import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import title_image from '../images/titles/cloud_computing.jpg'


const pageTitle = "Industry Expertise";
const pageSlug = "industry-expertise";


class IndustryExpertise extends React.Component{
    render(){
      return (
        <Layout>
          <section
            className="section page-title"
            style={{ backgroundImage: `url(${title_image})` }}
          >
            <div className="overlay"></div>
            <div className="container">
              <h1 className="title">{pageTitle}</h1>
              <h6 className="subtitle">
                We offer cloud computing services for all the steps of your
                journey.
              </h6>
            </div>
          </section>

          <section
            id="p_technology_services"
            className="section static-page page-content"
          >
            <div className="container">
              <div className="columns">
                <div className="column is-12 content-area"></div>
              </div>
            </div>
          </section>
        </Layout>
      )
  }
}

export default IndustryExpertise
